import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Grid,
  theme,
  Button,
  GridItem,
  Spinner,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { useState } from "react"


type AITextGenRequest = {
  system: string
  user: string
}

export async function generateAIText(data: AITextGenRequest): Promise<string> {
  return fetch(`https://api.nextbeer.dk/admin/ai/generate`, {
    method: 'POST',
    headers: {
      'mzXgPxDHtrTY': 'e734ce28-f74b-4498-9fc8-a02380711525',
    },
    body: JSON.stringify(data)
  }).then(r => r.text())
}

export const App = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [state, setState] = useState<string>("0")
  const isEmpty = state === "0"

  const addSymbol = (symbol: string) =>
    () => setState(state => isEmpty ? symbol : `${state}${symbol}`)

  const negate = () => setState(state => {
    return state.startsWith('-') ? state.replace('-', '') : "-" + state
  })
  const clear = () => setState("0")
  const submit = async () => {
    if (isLoading === false) {
      setIsLoading(true)
      const result = await generateAIText({
        system: 'You are a calculator. You will be given an expression and you must always return a number. If the expression is invalid just return 0',
        user: state
      })
      setState(result)
      setIsLoading(false)
    }
  }

  return <ChakraProvider theme={theme}>
    <Box textAlign="center" fontSize="xl">
      <Grid minH="100vh" p={3}>
        <ColorModeSwitcher justifySelf="flex-end" />
        <VStack spacing={8}>
          <Grid templateColumns='repeat(4, 1fr)' gap={4}>
            <GridItem colSpan={4} borderWidth={'1px'} borderRadius={'4px'} p={2}>
              <Text align={"end"}>{state}</Text>
            </GridItem>
            <Button onClick={clear}>C</Button>
            <Button onClick={negate}>+/-</Button>
            <Button onClick={addSymbol('%')}>%</Button>
            <Button onClick={addSymbol('/')}>/</Button>
            <Button onClick={addSymbol('7')}>7</Button>
            <Button onClick={addSymbol('8')}>8</Button>
            <Button onClick={addSymbol('9')}>9</Button>
            <Button onClick={addSymbol('*')}>*</Button>
            <Button onClick={addSymbol('4')}>4</Button>
            <Button onClick={addSymbol('5')}>5</Button>
            <Button onClick={addSymbol('6')}>6</Button>
            <Button onClick={addSymbol('-')}>-</Button>
            <Button onClick={addSymbol('1')}>1</Button>
            <Button onClick={addSymbol('2')}>2</Button>
            <Button onClick={addSymbol('3')}>3</Button>
            <Button onClick={addSymbol('+')}>+</Button>
            <GridItem colSpan={2}><Button width={"100%"} onClick={addSymbol("0")}>0</Button></GridItem>
            <Button onClick={addSymbol('.')}>.</Button>
            <Button onClick={submit}>
              {!isLoading && "=" }
              {isLoading && <Spinner></Spinner>}
              </Button>
          </Grid>
        </VStack>
      </Grid>
    </Box>
  </ChakraProvider>
}
